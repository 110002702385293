import React, { useState, useEffect } from "react";
import "./oneLesson.scss";

// Component to display teacher name & change teacher (if user is admin)
import TeacherToggle, { PlainName } from "./TeacherToggle";

// OneLesson makes a call to the database to update the teacher for a lesson
import { assignTeacher } from "../../../services/datastore";

// Add useful decorations to lesson object
import helpers from "./lessonDisplayHelpers";

// Toggle button icon

/**
 * Displays information for one lesson
 *
 * Display is modified for admin vs non-admin users, and admins also have added
 * functionaligy (e.g. change teacher)
 *
 * @param { Object }lesson      object with all lesson info from database
 * @param { String } specialType string lesson type: '', 'unclaimed', 'incomplete', 'past'
 * @param { Function } onClickFunc default function to execute on click of any area of markup
 * @param { Boolean } userIsAdmin boolean from db indicating if user has admin permission
 * @param { array } allTeachers array of objects with name and email for all teachers
 * @param { Boolean } showCompletionStatus - special flag for if should display completion status of PDN
 * @param { Boolean } disableTeacherChange - special flag to not allow teacher changing, even if otherwise you could
 * @param { Function } updateLessonTeacher - used to set new teacher on a lesson object in homepage state
 * @returns markup to display one lesson
 */
const OneLesson = ({
  lesson, specialType, onClickFunc, userIsAdmin, allTeachers, showCompletionStatus, disableTeacherChange, updateLessonTeacher,
}) => {
  /*
   * STATE
   */

  // Controls whether or not to display the change teacher dropdown
  const [changeTeacher, setChangeTeacher] = useState(false);

  // Teacher currently assigned to this lesson - changed by dropdown
  const [currentTeacher, setCurrentTeacher] = useState({
    preferred_name: "",
    email: "",
  });

  // Stores information on requested change to teacher
  const [requestStatus, setRequestStatus] = useState({
    attempted: false, // Changed to true when a teacher change is attempted
    success: null, // Changed to true if attempt is successful, false if not
    message: "", // Updated with message to display based on result of change
  });

  /*
   * USE EFFECT
   */
  // Will only fire on initial component load
  // uses info from passed lesson to set currentTeacher in state
  useEffect(() => {
    if (lesson) {
      // Set local state with teacher info - used for name display & teacher change
      setCurrentTeacher({
        preferred_name: helpers.getInstructor(lesson),
        email: lesson.instructor_email,
      });
    }
  }, []);

  /*
   * DISPLAY
   */
  // Generate className for special lesson types
  const lessonClass = specialType ? `${specialType}-lesson` : "default-lesson";

  // Need date and time for special lesson types, just time for default (upcoming)
  const lessonTime = specialType ? helpers.getDate(lesson, { withTime: true }) : helpers.getTime(lesson);

  // className for outer div
  let outerDivClassName = lessonClass;
  
  switch(lesson.lesson_event_type_category) {
    case "Podcast":
      outerDivClassName += " podcast";
      break;
    case "Video":
      outerDivClassName += " video";
      break;
    case "Welcome":
      outerDivClassName += " welcome";
      break;
    case "1on1":
      outerDivClassName += " oneonone";
      break;
    default:
      outerDivClassName += "";
  }

  // Get lesson topic, or default string if no topic is set
  const lessonTopic = Object.prototype.hasOwnProperty.call(lesson, "module") && lesson.module
    ? `${lesson.module.difficulty} ${lesson.module.unit_name} ${lesson.module.unit_number}: ${lesson.module.lesson_name} (${lesson.module.category})`
    : "Tema aún no seleccionado";

  /*
   * EVENT HANDLERS
   */

  // When admin clicks on teacher name, display the dropdown to change it
  const openTeacherChangeForm = () => {
    // If user is not admin, just do the default onClick action
    // Same if lesson has specialType (is incomplete or unassigned)
    if (userIsAdmin !== true || specialType) {
      onClickFunc();

      return;
    }
    console.log("openTeacherChangeForm CALLED!");
    // Display the drop-down with all teacher names by changing state
    setChangeTeacher(true);
    console.log("changeTeacher state value AFTER set:", changeTeacher);
  };

  // Helper used in click listener to close change teacher dropdown
  const closeTeacherChangeForm = () => {
    setChangeTeacher(false);
  };

  // When the name in the teacher dropdown is changed, handle the update
  const changeTeacherFunc = (event) => {
    // This function should never be called when user isn't admin, but let's be safe
    if (userIsAdmin !== true) {
      onClickFunc();
      return;
    }

    // Use event.target.value (teacher preferred name) to get selected teacher info from allTeachers
    const newTeacher = allTeachers.find((t) => t.preferred_name === event.target.value);

    // Send new teacher email and lesson ID to back-end for assignment
    assignTeacher(newTeacher.email, lesson.id, (res) => {
      // Response should just consist of object in format { success: [boolean] }
      // If we did good, update all the things!
      if (res.success === true) {
        // Display success message
        setRequestStatus({
          attempted: true, // We made a call
          success: true, // It worked
          message: `Teacher changed from ${currentTeacher.preferred_name} to ${newTeacher.preferred_name}`,
        });
        
        // Set the local state to match back-end update
        setCurrentTeacher(newTeacher);

        // Update state of lesson object in homepage
        updateLessonTeacher(lesson.date, lesson.id, newTeacher);

        // Close the dropdown
        setChangeTeacher(false);
      }
      else {
        // Check for custom error code that indicates fail was due to complete lesson
        const message = res.status === 490 ? "Cannot change teacher: This lesson is complete." : "Update failed. Please try again.";
        // Display failure message
        setRequestStatus({
          attempted: true, // We made a call
          success: false, // It failed
          message,
        });
      }
    });
  };

  return (
    <div
      className={outerDivClassName}
    >
      <div className="mainClassInfo">
        <div className="mainClassInfo__inner">
          {// If drill has been killed, display warning
            lesson.isKilled
          && <span className="lesson--killed">KILLED </span>
          }
          {// Display teacher toggle only if user is admin and lesson isn't killed
          // Also disable toggle if disableTeacherChagne flag is set
            userIsAdmin && (
              (lesson.isKilled || disableTeacherChange)
                ? (
                  <PlainName onClick={onClickFunc} displayName={currentTeacher.preferred_name} />
                )
                : (
                  <TeacherToggle
                    changeTeacher={changeTeacher}
                    currentTeacher={currentTeacher}
                    allTeachers={allTeachers}
                    changeTeacherFunc={changeTeacherFunc}
                    openTeacherChangeForm={openTeacherChangeForm}
                    closeTeacherChangeForm={closeTeacherChangeForm}
                  />
                )
            )
          }
          {// Display "Unclaimed" in styled component for unclaimed lessons
            (!userIsAdmin && specialType === "unclaimed") && <PlainName onClick={onClickFunc} displayName={currentTeacher.preferred_name} />
          }
          <span role="button" tabIndex="0" onClick={onClickFunc}>
            {// Display spacer only if teacher name has been displayed
              (userIsAdmin || specialType === "unclaimed") && " - "
            }
            <span className="lesson-dateTime">
              {lessonTime}
            </span>
            {" - "}
            <span className="lesson-level">
              {lesson.lesson_level && ["1", "2", "3"].includes(lesson.lesson_level) ? `Level ${lesson.lesson_level}` : "No Level"}
            </span>
            {// Display lesson topic only non-special lessons
              !specialType && (<span className="lessonTopic">{lessonTopic}</span>)
            }
          </span>
        </div>
        {// Display lesson type only for non-special lessons
          !specialType && (<span className="mainClassInfo__inner lessonType" onClick={onClickFunc}>{lesson.lesson_title}</span>)
        }
      </div>
      {
        !(lesson.isKilled && !lesson.complete)
          ? (
            // If showCompletionStatus flag is present, indicate PDN completed
            showCompletionStatus && (
              lesson.complete
                ? (
                  <div className="lesson-isCompleteFlag">Post Drill Notes submitted</div>
                )
                : (
                  <div className="lesson-isNotCompleteFlag">INCOMPLETE - please submit Post Drill Notes</div>
                )
            )
          )
          : (
            <div />
          )
      }
      { // Display status message after teacher change is attempted
        requestStatus.attempted && <div className={`teacherUpdateStatus teacherUpdateStatus--${requestStatus.success ? "success" : "failure"}`}>{requestStatus.message}</div>
      }
    </div>
  );
};

export default OneLesson;
