// this is not operational. 
// TODO: determine if it's worth adding this
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getOneStory, editStory } from "../../../services/datastore";

/**
 * EditStory Component - Form for editing existing stories
 * @param {Object} story - Story object containing current story details
 * @returns {JSX.Element} Story editing form
 */
export default function EditStory({ story }) {
  const search = useLocation().search;

  const [storyData, setStoryData] = useState({
    storyWorld: "",
    storyChapter: "",
    storyNumber: "",
    storyName: "",
    description: "",
    hideFromStoryTree: false,
    quizUrl: "",
    relatedDrillTopic1: {
      name: "",
      urlL1: "",
      urlL2: "",
      urlL3: "",
    },
    storyText: "",
  });

  // Handles whether or not the error message is shown
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Error that displays when a user enters bad input into story Name
  const [storyNameError, setStoryNameError] = useState(null);

  // Handles whether or not the success message is shown
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Controls whether or not the submit button is clickable
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    const storyIdFromQueryString = new URLSearchParams(search).get("storyId");

    if (story.id && !storyIdFromQueryString) {
      history.pushState(null, null, `/manage/stories?storyId=${story.id}`);
    }
    
    if (story.id) {
      setDefaultState(story);
      setStoryData({
        ...story,
        hideFromStoryTree: story.hideFromStoryTree || false,
      });
    }
    else if (storyIdFromQueryString) {
      asyncFetchStory(storyIdFromQueryString);
    }
  }, []);

  const asyncFetchStory = async (storyId) => {
    const fetchedStory = await getOneStory(storyId);
    setDefaultState(fetchedStory);
    setStoryData({
      ...fetchedStory,
      hideFromStoryTree: fetchedStory.hideFromStoryTree || false,
    });
  };

  const setDefaultState = (storyData) => {
    handleStoryDataBooleans(storyData, "hideFromStoryTree");
    storyData["uneditedStoryId"] = storyData.id;
  };

  const handleSubmit = async (event) => {
    setDisableSubmit(true);
    event.preventDefault();
    const result = await editStory(storyData);
    result ? setShowSuccessMessage(true) : setShowErrorMessage(true);
  };

  const resetStatus = () => {
    setShowErrorMessage(false);
    setStoryNameError("");
    setShowSuccessMessage(false);
    setDisableSubmit(false);
  };

  // Handles changes for nested relatedDrillTopic1 object
  const handleDrillTopicChange = (event) => {
    const key = event.target.name;
    const value = trimSpaces(event.target.value);
    setStoryData({
      ...storyData,
      relatedDrillTopic1: {
        ...storyData.relatedDrillTopic1,
        [key]: value,
      },
    });
    resetStatus();
  };

  const handleChange = (event) => {
    const key = event.target.name;
    const value = trimSpaces(event.target.value);
    
    if (key === "storyName" && (value.length !== 0 && value.trim().length === 0)) {
      setDisableSubmit(true);
      setStoryNameError("The story name must not be blank or contain only spaces");
    }
    else {
      setStoryData({ ...storyData, [key]: value });
      resetStatus();
    }
  };

  const trimSpaces = (string) => {
    if (typeof string !== "string" || !string) {
      return string;
    }
    return string.trim();
  };

  const handleStoryDataBooleans = (storyData, key) => {
    storyData[key] = storyData[key] === undefined ? "false" : storyData[key].toString();
  };

  return storyData ? (
    <div className="moduleDetails">
      {showSuccessMessage ? (
        <p className="add-module-success-message">
          The story has been successfully edited.
        </p>
      ) : null}
      {showErrorMessage ? (
        <p className="add-module-error-message">
          The story you are attempting to edit does not exist.
        </p>
      ) : null}
      <form className="moduleInformation" onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="storyWorld">
            Story World:
            <input
              name="storyWorld"
              type="text"
              className="input"
              value={storyData.storyWorld || ""}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyChapter">
            Story Chapter:
            <input
              name="storyChapter"
              type="text"
              className="input"
              value={storyData.storyChapter || ""}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyNumber">
            Story Number:
            <input
              name="storyNumber"
              type="text"
              className="input"
              value={storyData.storyNumber || ""}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyName">
            Story Name:
            <input
              name="storyName"
              type="text"
              className="input"
              value={storyData.storyName || ""}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>
        {storyNameError ? (
          <p className="lesson-name-error-message">{storyNameError}</p>
        ) : null}

        <div className="input-field">
          <label htmlFor="description">
            Description:
            <textarea
              name="description"
              className="input"
              value={storyData.description || ""}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="hideFromStoryTree">
            Hide from Story Tree:
            <select
              name="hideFromStoryTree"
              value={storyData.hideFromStoryTree}
              onChange={(event) => {
                setStoryData({
                  ...storyData,
                  hideFromStoryTree: event.target.value === "true",
                });
                resetStatus();
              }}
            >
              <option value={false}>False</option>
              <option value={true}>True</option>
            </select>
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="quizUrl">
            Quiz URL:
            <input
              name="quizUrl"
              type="text"
              className="input"
              value={storyData.quizUrl || ""}
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="name">
            Related Drill Topic Name:
            <input
              name="name"
              type="text"
              className="input"
              value={storyData.relatedDrillTopic1?.name || ""}
              required
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="urlL1">
            L1 URL:
            <input
              name="urlL1"
              type="text"
              className="input"
              value={storyData.relatedDrillTopic1?.urlL1 || ""}
              required
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="urlL2">
            L2 URL:
            <input
              name="urlL2"
              type="text"
              className="input"
              value={storyData.relatedDrillTopic1?.urlL2 || ""}
              required
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="urlL3">
            L3 URL:
            <input
              name="urlL3"
              type="text"
              className="input"
              value={storyData.relatedDrillTopic1?.urlL3 || ""}
              required
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyText">
            Story Text:
            <textarea
              name="storyText"
              className="input"
              style={{ height: "200px" }}
              value={storyData.storyText || ""}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <br />
        <div>
          <input
            className={
              disableSubmit
                ? "add-module-submit-button-disabled"
                : "add-module-submit-button"
            }
            type="submit"
            value="Edit Story"
            disabled={disableSubmit}
          />
        </div>
      </form>
      {showSuccessMessage ? (
        <p className="add-module-success-message">
          The story has been successfully edited.
        </p>
      ) : null}
      {showErrorMessage ? (
        <p className="add-module-error-message">
          The story you are attempting to edit does not exist.
        </p>
      ) : null}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
