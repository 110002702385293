import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step} from "react-step-progress-bar";
import "./customProgressBar.scss";
import talkkaSmile from "../../../../images/talkka_smile.svg";
import talkkaWink from "../../../../images/talkka_wink.svg";

/**
 * Custom progress bar component that wraps react-step-progress-bar
 * @param {Object} props - Component props
 * @param {number} props.percent - The percentage to fill the progress bar (0-100)
 * @param {number} props.totalBeepboops - The total number of beepboops
 */
class CustomProgressBar extends React.Component {
  render() {
    const { percent, totalBeepboops } = this.props;

    return (
      <div className="container">
        <div className="customProgressBar">
          <ProgressBar
            percent={percent}
            filledBackground="linear-gradient(to right, #a8e063, #56ab2f)"
            width="90%"
          >
            <Step transition="scale">
              {({ accomplished, index }) => (
                <div className={`indexedStep ${accomplished ? null : null}`}>
                  {index}
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished}) => (
                <div className="indexedStep highlighted">
                  <div className="imageContainer">
                    <img
                      style={{ opacity: accomplished ? 1 : 0 }}
                      width="30"
                      src={talkkaSmile}
                    />
                    <div className={`indexNumber ${accomplished ? "accomplished" : null}`}>{5}</div>
                  </div>
                </div>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <div className="indexedStep highlighted">
                  <div className="imageContainer">
                    <img
                      style={{ opacity: accomplished ? 1 : 0 }}
                      width="30"
                      src={talkkaWink}
                    />
                    <div className={`indexNumber ${accomplished ? "accomplished" : null}`}>{9}</div>
                  </div>
                </div>
              )}
            </Step>
          </ProgressBar>
        </div>
        <div className="supportiveMessage">
          {totalBeepboops === 1 && "¡Bien hecho! Más Beepboops compasivos"}
          {totalBeepboops === 5 && "¡A mitad de camino!"}
          {totalBeepboops === 9 && "¡Increíble!"}
        </div>
      </div>
    );
  }
}

export default CustomProgressBar;
