import React /*, { useEffect, useState }*/ from "react";

import { useHolodeck } from "../Holodeck";

import SlideArrows from "./SlideArrows";
import StudentPresence from "./StudentPresence";
import CustomProgressBar from "./CustomProgressBar";
import "./commandStation.scss";

/**
 * Renders control buttons for lesson
 * 
 * @param { Object } lesson - lesson information from database
 * @param { Object } module - Firestore object for module associated with lesson
 * @param { Number } slideFrameIndex - Slide index as reported by slides.com
 * iframe. Should (but doesn't always) match slideIndex
 * menu (in Teach.js state)
 * @returns markup for command station
 */
const CommandStation = ({
  lesson,
  module,
  slideFrameIndex,
}) => {
  // Get needed Daily info & setters from context
  const {
    allSpeakMode,
    // candidateCount,
    conversationMode,
    // fillDrill,
    setConversationMode,
    setSlideIndex,
    // speakerCount,
    toggleAllSpeakMode,
    totalBeepboops,
  } = useHolodeck();

  /*
   * STATE
   */

  // Boolean indicating if lesson started 30 secs or more ago
  // const [ isLessonUnderway, setIsLessonUnderway ] = useState(false);

  // Boolean indicating if Fill Drill has been clicked within the last minute
  // const [ fillDrillButtonClickedRecently, setFillDrillButtonClickedRecently ] = useState(false);

  // Boolean indicating if Fill Drill button should be clickable
  // const [ canFillDrill, setCanFillDrill ] = useState(false);

  /*
   * USE EFFECT
   */

  // When lesson is loaded, update isLessonUnderway
  // useEffect(() => {
  //   // If no lesson file, return
  //   if ( !lesson ) return;

  //   // Get the current time in milliseconds
  //   const nowMillis = (new Date()).getTime();

  //   // Compute the time between now and 30 secs past the lesson start time
  //   const millisToLessonUnderway =
  //     (new Date(lesson.lessonScheduledStartUTC)).getTime() + (30 * 1000)
  //     - nowMillis;

  //   // If millisToLessonUnderway is positive, set a timer to toggle isLessonUnderway
  //   if (millisToLessonUnderway > 0) {

  //     const underwayTimeout = setTimeout(() => {
  //       setIsLessonUnderway(true);
  //     }, millisToLessonUnderway);

  //     // Clear timer on unmount
  //     return function clearUnderwayTimeout() {
  //       clearTimeout(underwayTimeout);
  //     };
  //   }
  //   // If millisToLessonUnderway isn't positive, then the lesson is already under way, and we can immediately toggle to true
  //   setIsLessonUnderway(true);

  // }, [ lesson ]);

  // When Fill Drill button is clicked, wait a minute until can click again
  // useEffect(() => {
  //   // Helper to clear recently-clicked flag
  //   const clearFillDrillButtonClickedRecently = () => {
  //     setFillDrillButtonClickedRecently(false);
  //   };

  //   // Only take action if fillDrillButtonClickedRecently has just been toggled to true
  //   if ( fillDrillButtonClickedRecently ) {
  //     // Wait 1 min, then declare it no longer a recent click
  //     const clickedRecentlyTimeout = setTimeout(clearFillDrillButtonClickedRecently, 60 * 1000);

  //     // Clear timout when done
  //     return function clearClickedRecentlyTimeout() {
  //       clearTimeout( clickedRecentlyTimeout );
  //     };
  //   }
  // }, [ fillDrillButtonClickedRecently ]);

  // Whenever any factor relating to clickability of Fill Drill changes, recalculate canFillDrill
  // useEffect(() => {
  //   // Set canFillDrill to false if any requirements fail
  //   setCanFillDrill(
  //     // Drill must have started at least 30 secs ago
  //     isLessonUnderway
  //     // Must not have clicked in last minute
  //     && !fillDrillButtonClickedRecently
  //     // Need at least 1 Candidate in the room
  //     && candidateCount > 0
  //     // Need at most 3 Speakers in the room
  //     && speakerCount < 4,
  //   );
  // }, [
  //   candidateCount,
  //   fillDrillButtonClickedRecently,
  //   isLessonUnderway,
  //   speakerCount,
  // ]);

  /*
   * CLICK HANDLERS
   */

  // Handler for mode button click
  const handleModeChange = () => {
    // Only allow if module is set
    if ( !module ) {
      return alert("Must set module before entering Drill Mode");
    }

    // If we're toggling out of Convo mode and All Speak is on, turn it off
    if ( conversationMode && allSpeakMode ) {
      toggleAllSpeakMode();
    }

    // Toggle conversation mode
    setConversationMode(!conversationMode);
  };

  // Handler for Sección Final button click
  const handleSeccionFinalClick = () => {
    // Only allow if module is set
    if ( !module ) {
      return alert("Must set module before jumping to Sección Final");
    }

    // use javascript browser confirm dialog to ensure instructor wants to go to Sección Final (so they don't accidentally click it during class)
    if (confirm("Are you sure you want to start Sección Final Conversation?")) {
      
      // Send questionsAndConversationSectionSlideIndex - 1 to slides.com
      setSlideIndex(module?.questionsAndConversationSectionSlideIndex - 1);

      // If we're already in Convo mode and All Speak is on, turn it off
      if ( allSpeakMode ) {
        toggleAllSpeakMode();
      }

      // Also switch to Conversation Mode
      setConversationMode(true);
    }
  };

  // Handler for Fill Drill button click
  // const handleFillDrillClick = () => {
  //   // If can't fill drill, don't do anything!
  //   if ( !canFillDrill ) return;

  //   // Indicate recent click
  //   setFillDrillButtonClickedRecently(true);

  //   // Use Holodeck helper to promote Candidates
  //   fillDrill( lesson?.id );
  // };
 
  return (
    <div className="commandStationContainer">
      <div className="commandStation__topRow">
        <div
          className={`commandStation__button seccionFinalButton ${module ? "active" : "inactive"}`}
          onClick={ handleSeccionFinalClick }
        >
          SeccFin
        </div>
        <SlideArrows
          slideFrameIndex={slideFrameIndex}
        />
      </div>
      <StudentPresence
        lesson={lesson}
        module={module}
      />
      <div className="commandStation__bottomRow">
        <div
          className={`commandStation__button modeButton ${module ? "active" : "inactive"}`}
          onClick={ handleModeChange }
        >
          {conversationMode
            ? "Start Drill"
            : "Start Conv"
          }
        </div>
        {// In conversation mode, show toggle between All Speak and Speakers-only Convo mode
          conversationMode
          && (
            <label
              className="commandStation__allModeLabel"
            >
              <input
                className="commandStation__allModeInput"
                type="checkbox"
                checked={ allSpeakMode }
                onChange={ toggleAllSpeakMode }
              />
              <span className="commandStation__allModeSlider"></span>
            </label>
          )
        }
        {/* <div
          className={`commandStation__button fillDrillButton ${canFillDrill ? "active" : "inactive"}`}
          onClick={ handleFillDrillClick }
        >
          Fill Drill
        </div> */}
      </div>

      <div className="commandStation__studentPresence">
        {conversationMode ? allSpeakMode ? "All Students Conv." : "Only Speakers: Conv." : "Drill"} Mode
      </div>

      <div className="commandStation__studentPresence">
        <CustomProgressBar percent={(totalBeepboops / 9) * 100} totalBeepboops={totalBeepboops} />
      </div>

    </div>
  );
};

export default CommandStation;
