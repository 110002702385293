/* DATASTORE.JS
  * this file contains functions that bridge the frontend and the backend database
  * this makes calls to our backend server via API requests
  * we pass in different parameters and send them to the api routes that we specify
  * then we wait for a response from the backend, and we persist this information back to the frontend
*/

/*
 * CALL PATTERN FOR NEW/REFACTORED FUNCTIONS
 * 
 * Use async/await instead of promise chaining
 * Avoid callbacks - return useful info from datastore function, instead
 * If call succeeds, return data or true to calling component
 * If call fails, return error information or false to calling component
 * Never console.log a response
 * If needed, log a minimal message identifying where the error occurred
 */

import { getIdToken, signInWithEmailAndPassword } from "firebase/auth";

import version from "../../../version/version.json";
import activebuildconfig from "../../../configs/activebuildconfig.json";

import { firebaseAuth } from "../base";

import { talkka } from "../logging";

import { asyncFetchResult } from "./asyncFetchResult";

const APIRootUrl = activebuildconfig.API_ROOT_URL;

/*
* this function handles sign in attempts to the Teach App
*/
export function signIn(email, password, history, callback) {
  // first authenticate the user
  signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(() => {
      // if successfully authenticated, then authorize
      fetch(`${APIRootUrl}/teacher_signin`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          version: version.version,
        }),
      })
        .then((res) => {
          // successful authorization
          const output = res;
          output.json()
            .then((data) => {
              // if they exist in the database and have permission to log in, then sign them in
              // through firebase
              if (data.success) {
                callback(true, null);
              }
              else {
                callback(false, data.error);
              }
            })
            .catch(() => {
              callback(false, "internal error");
            });
        })
        .catch(() => {
          // authorization error
          callback(false, "auth/user-not-found");
        });
    })
    .catch(() => {
      // authentication error
      callback(false, "auth/user-not-found");
    });
}

/**
 * Fetch full lesson object from Firestore
 *
 * @param { String } lessonId - to fetch
 */
export const fetchLessonById = async ( lessonId ) => {
  try {
    // Use helper to fetch data from our API, retrying once if initial call fails
    const result = await asyncFetchResult({
      endpoint: "get_one_lesson",
      body: { lessonId },
      retryOnFailure: true,
    });

    if (
      !result?.success
      || !result.data
    ) {
      throw new Error("Unable to fetch lesson - check Cloud Function logs");
    }

    return result.data;
  }
  catch ( error ) {
    talkka.error(`Error fetching lesson info by id: ${ error }`);
    return false;
  }
};

/**
 * Update the database when a teacher changes the module for a lesson
 */
export const updateLessonModuleById = async (lessonId, moduleId) => {
  try {
    // Make sure we have required inputs
    if (!lessonId || !moduleId) throw new Error("insufficient arguments");

    const token = await getIdToken(firebaseAuth.currentUser);

    const response = await fetch(`${APIRootUrl}/teach/update_lesson_module_by_id`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId,
        moduleId,
      }),
    });

    const result = await response.json();

    if ( !result.success || !result.data ) {
      throw new Error("could not unpack response");
    }

    // Return module to calling function
    return result.data;
  }
  catch (error) {
    console.error(`Could not update lesson module: ${error}`);
    return false;
  }
};

// Tracks seccion-final & reestablecer turnos actions
// studentAffected and additionalInfo are always passed in as empty strings
export function trackAction(actionTaken, actionTrigger, lessonId, UTCTimestamp, studentAffected, additionalInfo) {
  getIdToken(firebaseAuth.currentUser).then((idToken) => {
    fetch(`${APIRootUrl}/track_action`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token: idToken,
        actionTaken,
        actionTrigger,
        lessonId,
        UTCTimestamp,
        studentAffected,
        additionalInfo,
        actionVersion: version.version,
      }),
    })
      .then(() => {
      // Return true to indicate that request successfully completed
        return true;
      })
      .catch(() => {
        console.error("Error in tracking lesson action");
        // Return false to indicate that an error occurred
        return false;
      });
  });
}

// Create a new user
export function createUser(email, password, callback1) {
  fetch(`${APIRootUrl}/teach/public/teacher_signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      version: version.version,
    }),
  }).then((res) => {
    const output = res;
    output.json()
      .then((result) => {
        const cred = result.data.loginCredentials;
        if (cred) {
          signIn(email, password, null, () => {
            callback1(true, null);
          });
        }
        else {
          callback1(false, result.error);
        }
      });
  })
    .catch(() => {
      console.error("error in new teacher sign-up");
      return false;
    });
}

/**
 * Gets all lessons for the given date range
 *
 * @param { String } startDate (MM/DD/YYYY) - date to retrieve, or start date for range to retrieve
 * @param { String } endDate [optional] (MM/DD/YYYY) - end date (exclusive) for range to retrieve
 * @returns date-sorted object of past, future, and today lessons
 */
export const asyncGetLessonsByDate = async (startDate, endDate = false) => {
  // Use helper to get data from back end
  const result = await asyncFetchResult({
    endpoint: "get_lessons_by_date",
    verb: "PUT",
    body: { startDate, endDate },
  });

  // Return fetched lessons data, or false if call failed
  return result.success ? result.data : false;
};

// Function to get all lessons (regardless of date) with specified status
export const asyncGetAllLessonsWithSpecialStatus = async (lessonStatus) => {
  try {
    // Only valid special statuses are incomplete and unclaimed
    const validStatuses = ["incomplete", "unclaimed"];

    // Throw an error if an invalid status was passed
    if (!validStatuses.includes(lessonStatus)) {
      throw new Error("Invalid status passed to getAllLessonsWithSpecialStatus");
    }

    // If we have a valid status, get the lessons!

    // Grab the user's token
    const token = await getIdToken(firebaseAuth.currentUser);

    // Send request to the back end
    const response = await fetch(`${APIRootUrl}/teach/get_all_lessons`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        filter: lessonStatus,
        version: version.version,
      }),
    });

    // extract JSON from return
    const result = await response.json();

    // Failure pattern is success: false
    if (!result.success) {
      throw new Error(`Could not fetch ${lessonStatus} lessons`);
    }

    // Return fetched lessons
    return result.data;
  }
  catch {
    console.error("Error getting special lessons");
    // Return false on error -- component interprets this as no data
    return false;
  }
};

/**
 * Claims an unclaimed Lesson
 * @param {Object} claimedLesson - the lesson object from which we extract the id
 * @returns nothing (Just general success flag)
 */
export const claimLesson = async (claimedLesson) => {
  try {
    // Get user token
    const token = await getIdToken(firebaseAuth.currentUser);

    // Attempt to claim lesson in Firestore
    const response = await fetch(`${APIRootUrl}/claim_lesson`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId: claimedLesson.id,
        version: version.version,
      }),
    });

    // Extract JSON from response
    const result = await response.json();

    // Return true if operation succeeded
    if (result.success) {
      return true;
    }
    else {
    // Return false and log error if failed
      console.error("Failed to claim lesson");
      return false;
    }
  }
  catch {
    console.error("Error when claiming lesson");
    return false;
  }
};

/**
 * unClaims a claimed lesson
 * @param {Object} unClaimedLesson - the lesson object from which we extract the id
 * @param {Date} date - date object that is currently not being used
 * @param {Object} history - history object used to push a new route onto the user's history stack
 * @param {function} callback - function called after the result has been returned
 * @returns nothing (Just general success flag)
 */
export function unClaimLesson(unClaimedLesson, date, history, callback) {
  getIdToken(firebaseAuth.currentUser).then((idToken) => {
    // Send token to your backend via HTTPS
    // ...
    fetch(`${APIRootUrl}/unclaim_lesson`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token: idToken,
        lessonId: unClaimedLesson.id,
        version: version.version,
      }),
    }).then((res) => {
      const output = res;
      output.json()
        .then((json) => {
          if (json.success) {
            history.push("/");
            callback();
          }
          else {
            console.error("failed to unclaim lesson");
            return false;
          }
        });
    })
      .catch(() => {
        console.error("error getting zoom info when unclaiming lesson");
        return false;
      });
  })
    .catch(() => {
      // Handle error
      console.error("error getting id token when unclaiming lesson");
      return false;
    });
}

// Gets database info for logged-in teacher
export const getUserInfo = async () => {
  try {
    // Get user token
    const token = await getIdToken(firebaseAuth.currentUser);

    // Get info for logged-in teacher
    const response = await fetch(`${APIRootUrl}/teachers`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        version: version.version,
      }),
    });

    // Extract JSON from response
    const result = await response.json();

    // If successful, send data to calling component
    if (result.success) {
      return result.data;
    }

    // If no success flag, throw error
    throw new Error("Could not find user in database");
  }
  catch (error) {
    console.log(`Failed to get user info with error: ${error}`);
    return false;
  }
};

/**
 * API wrapper for 'make_synthetic_booking'
 * @param {Object} bookingFields - fields containing details of the booking
 * @returns {string} `null` if the call succeeded, else an error message from the API
 */
export async function makeSyntheticBooking(bookingFields) {
  try {
    const idToken = await getIdToken(firebaseAuth.currentUser);
    const response = await fetch(`${APIRootUrl}/make_synthetic_booking`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token: idToken,
        ...bookingFields,
      }),
    });

    const result = await response.json();
    if (result.success) {
      return null;
    }
    else {
      throw new Error(result.error);
    }
  }
  catch (error) {
    // Returning error to component
    return `makeSyntheticBooking failed - ${error}`;
  }
}

/**
 * Get basic info (name and email) for all teachers
 * Used in homepage admin view for teacher sorting and changing
 * 
 * @returns An array of teacher objects on success, or False on request failure
 */
export const asyncGetAllTeachers = async () => {
  try {
    // Get user token
    const token = await getIdToken(firebaseAuth.currentUser);

    // Get info for all teachers
    const response = await fetch(`${APIRootUrl}/teach/get_all_teachers`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        // endpoint requires admin token, else will return success: false
        token,
        fields: "email, preferred_name",
      }),
    });

    // Extract JSON from response
    const result = await response.json();

    // If we got a failure, throw an error
    if (result.success === false) {
      throw new Error();
    }

    // If successful, send data to calling component
    return result.data;
  }
  catch {
    console.log("Failed to fetch info for all teachers");
    return false;
  }
};

/**
 * Assigns the given teacher to the given lesson, then executes the provided
 * callback.
 *
 * @param {String} teacherEmail - for teacher who will be assigned to lesson
 * @param {String} lessonId - of lesson being assigned
 * @param {function} callback function to run on data returned from back-end
 * No return
 */
export function assignTeacher(teacherEmail, lessonId, callback) {
  // Authorize current user and get token to send to back end
  getIdToken(firebaseAuth.currentUser).then(
    (idToken) => {
      // Hit new assign teacher endpoint on back-end
      fetch(`${APIRootUrl}/teach/assign_teacher`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          // endpoint requires admin token, else will return success: false
          token: idToken,
          teacherEmail,
          lessonId,
        }),
      })
        .then((res) => {
          res.json().then((output) => {
            // If we have the JSON, run the callback
            // Add http status code to output
            callback({ ...output, status: res.status });
          }).catch(() => {
            console.error("assignTeacher response could not be converted to JSON");
            return false;
          });
        })
        .catch(() => {
          console.error("Error in assignTeacher");
          return false;
        });
    },
  );
}

/**
 * Sets the lesson to be killed, alerts students in lesson 
 * @param {String} lessonId - Id of the lesson to be killed
 * @returns nothing (Just general success flag)
 */
export async function killLesson(lessonId) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const result = await fetch(`${APIRootUrl}/kill_lesson`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId,
      }),
    });
    const json = await result.json();
    return json;
  }
  catch {
    console.error("Error when killing lesson");
    return false;
  }
}

/**
 * Get initial data needed to create a lesson report (in particular, information about students
 * in the lesson)
 * @param {string} lessonId of the lesson to get initial report data for
 * @returns Object returned by the endpoint
 */
export async function getLessonReportInitialState(lessonId) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      lessonId,
      token,
    };

    const response = await fetch(`${APIRootUrl}/teach/get_lesson_report_initial_state`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    
    // Return data without success flag
    return result.data;
  }
  catch {
    console.error("Error getting lesson report initial state");
    return false;
  }
}

/**
 * Submits a Drill lesson report
 *
 * @param {string} lessonId of the lesson to submit a report for
 * @param {*} feedback teacher feedback
 * @param {*} students students info (take value from getInitialLessonReportData, but modify for accuracy)
 * @returns Object returned by the endpoint
 */
export async function submitDrillLessonReport(lessonId, feedback, students) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      lessonId,
      feedback,
      students,
      teach_app_version: version.version,
    };

    const response = await fetch(`${APIRootUrl}/teach/submit_drill_lesson_report`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();

    // Return data with success flag
    return result;
  }
  catch {
    console.error("Error submitting Post Drill Notes");
    return false;
  }
}

/**
 * Get module details given a module ID
 *
 * @param {string} moduleId
 * @returns Object with module details
 */
export async function getOneModule(moduleId) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      moduleId,
    };

    const response = await fetch(`${APIRootUrl}/teach/get_one_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch {
    console.error("Error getting module"); 
    return false;
  }
}

/**
 * Get module vocab subcollection given a module ID
 *
 * @param {string} moduleId
 * @returns Object with module details
 */
export async function getVocabularyForModule(moduleId) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      moduleId,
    };

    const response = await fetch(`${APIRootUrl}/teach/get_vocabulary_for_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    // Failure pattern is success: false
    if (!responseBody.success) {
      return false;
    }
  
    return responseBody.data;
  }
  catch {
    console.log("Failed to get vocabulary for module");
  }
}

/**
 * Upload vocabulary for a specified module
 *
 * @param {string} moduleId
 * @param {string} rawVocabulary The proplerly formatted vocabulary exported from quizlet
 * @returns Object with module details
 */
export async function uploadVocabularyForModule(moduleId, rawVocabulary) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      moduleId,
      rawVocabulary,
    };

    const response = await fetch(`${APIRootUrl}/teach/upload_vocabulary_for_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    if (!responseBody.success) {
      return false;
    }
    return responseBody.data;
  }
  catch {
    console.log("Failed to upload vocabulary for module.");
  }
}

/**
 * Search the modules collection for modules matching a search key & filter, return at most 10 modules
 *
 * @param {string} searchKey the search key to use
 * @param {string} searchLanguage the language to limit searching to
 * @param {Array<string>} filters ['omitComingSoon', 'omitWelcome'] - filters to use
 * @returns array of at most 10 lessons matching the criteria
 */
export async function searchModules(searchKey, searchLanguage, filters = []) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      searchKey,
      searchLanguage,
      filters,
    };

    const response = await fetch(`${APIRootUrl}/teach/search_modules`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch (error) {
    console.error("Error in module search"); 
    return false;
  }
}

/**
 * Creates a new module from the data that an admin submitted
 *
 * @param {object} moduleData Contains all of the module data used to create the new module
 * @returns nothing (Just general success flag)
 */
export async function addModule(moduleData) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      moduleData,
    };

    const response = await fetch(`${APIRootUrl}/teach/add_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    return responseBody.success;
  }
  catch (error) {
    console.error("Error in add module"); 
    return false;
  }
}

/**
 * Change a student's student type
 *
 * @param { String } studentId - the ID of the student to modify
 * @param { String } currentStudentType - the student's current type
 * @param { String } targetStudentType - the new student type
 * @returns { Object } - with 'success' boolean flag or 'error' string
 */
export const changeStudentType = async (studentId, currentStudentType, targetStudentType) => {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);

    const response = await fetch(`${APIRootUrl}/teach/change_student_type`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        studentId,
        currentStudentType,
        targetStudentType,
      }),
    });

    return await response.json();
  }
  catch {
    return { success: false, error: "internal error" };
  }
};
/**
 * 
 * @param {string} email email of student to manage
 * @returns Student data object
 */
export async function getOneStudentToManage(email) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      email,
    }; 
    const rawResponse = await fetch(`${APIRootUrl}/teach/get_single_student`,{
      method:"PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const response = await rawResponse.json();
    return response.data;
  } 
  catch(error){
    return false;
  }
}
/**
 * Gets student data for one student given a student id
 *
 * @param { String } studentId studentId
 * @param { Array } fields [optional] fields to sort by
 */
export const getOneStudentById = async (id, fields = []) => {
  try {
    console.log(id);
    const result = await asyncFetchResult({
      endpoint: "get_one_student_by_id",
      body: {
        studentId: id,
        fields,
      },
    });

    if (
      !result
      || !result.success
      || !result.data
    ) {
      throw new Error("Bad fetch from back end - check CF logs");
    }

    return result.data;
  }
  catch ( error ) {
    talkka.error(`Error getting info for student: ${error}`);
    return false;
  }
};
/**
 * 
 * @param {string} query the query to search for. Checks that the fields defined in the query_students endpoint start with the query
 * @returns Array<Students>
 */
export async function studentQuery(query) {
  try{
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      query,
    };
    const rawResponse = await fetch(`${APIRootUrl}/teach/query_students`,{
      method:"PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const response = await rawResponse.json();
    return response.data;
  } 
  catch(error){
    return false;
  }
}

/**
 * Edits an existing module from the data that an admin submitted
 *
 * @param {object} moduleData Contains all of the module data used to edit the existing module
 * @returns nothing (Just general success flag)
 */
export async function editModule(moduleData) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      moduleData,
    };

    const response = await fetch(`${APIRootUrl}/teach/edit_module`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    return responseBody.success;
  }
  catch (error) {
    console.error("Error in edit module"); 
    return false;
  }
}

/**
 * Gets a valid token for use when joining a Daily room with owner permissions
 * 
 * @param { String } roomName - Name of Daily room with which the token will be
 * associated
 * @returns the token
 */
export const asyncGetDailyToken = async (roomName) => {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);

    const response = await fetch(`${APIRootUrl}/teach/get_daily_token`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        roomName,
        isOwner: true,
      }),
    });

    const result = await response.json();

    if (!result.success || !result.data) {
      throw new Error();
    }

    return result.data;
  }
  catch {
    console.error("Error getting Daily token");
    return false;
  }
};

/**
 * Fetches a lesson by its idHash field
 * 
 * @param { String } lessonIdHash - idHash of lesson to fetch
 * @returns object representing lesson in Firestore
 */
export const asyncGetLessonByIdHash = async (lessonIdHash) => {
  try {
    // Get user token from logged-in session
    const token = await getIdToken(firebaseAuth.currentUser);

    // Fetch lesson by id hash
    const response = await fetch(`${APIRootUrl}/teach/get_one_lesson`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonIdHash,
        version: version.version,
      }),
    });

    const result = await response.json();

    if (!result.success || !result.data) {
      throw new Error();
    }

    return result.data;
  }
  catch {
    console.error("Error getting lesson by hash");
    return false;
  }
};

/**
 * Fetches a wordBundle by moduleId
 * 
 * @param { String } moduleId - id of module to fetch wordBundle for
 * @returns object representing wordBundle in Firestore
 */
export const asyncGetWordBundleIdByModuleId = async (moduleId) => {
  try {
    // Get user token from logged-in session
    const token = await getIdToken(firebaseAuth.currentUser);

    // Fetch lesson by id hash
    const response = await fetch(`${APIRootUrl}/teach/get_word_bundle_id_by_module_id`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        moduleId,
      }),
    });

    const result = await response.json();

    if (!result.success || !result.data) {
      throw new Error();
    }

    return result.data;
  }
  catch {
    console.error("Error getting wordBundle by moduleId");
    return false;
  }
};

/**
 * Records start time of a lesson (only used for Daily lessons).
 * Only first request is honored.
 * 
 * @param { String } lessonId - ID of lesson to record start time for
 * @returns boolean indicating operation success or failure
 */
export const startLesson = async (lessonId) => {
  try {
    // Get user token from logged-in session
    const token = await getIdToken(firebaseAuth.currentUser);

    // Send request -- only first call to this endpoint writes to field
    const response = await fetch(`${APIRootUrl}/teach/start_lesson`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        lessonId,
        version: version.version,
      }),
    });

    const result = await response.json();

    if (!result.success) {
      throw new Error();
    }

    return result.success;
  }
  catch {
    console.error("Error recording lesson start time");
    return false;
  }
};

/**
 * Record beepboops and biens from WebDrills in teacherFeedback on Firestore
 * 
 * @param { String } lessonId - of lesson in which feedback was given
 * @param { String } moduleId - of module in which feedback was given
 * @param { Number } slideIndex - exact slide of module where feedback was given
 * @param { String } studentId - of student who got feedback
 * @param { Boolean } isBeepboop - true if beepboop, false if bien
 * @returns undefined - is fired and forgotten in CurrentStudentPanel
 */
export const recordFeedback = async (
  lessonId,
  moduleId,
  slideIndex,
  studentId,
  isBeepboop = false,
) => {
  try {
    // Construct feedback object from arguments
    const feedback = {
      lessonId,
      moduleId,
      studentId,
      slidesDotComIndex: slideIndex,
      feedbackType: isBeepboop ? "beepboop" : "bien",
      feedbackGivenAtUTC: new Date().toISOString(),
    };

    // Get user token from logged-in session
    const token = await getIdToken(firebaseAuth.currentUser);

    // Send request -- only first call to this endpoint writes to field
    const response = await fetch(`${APIRootUrl}/teach/record_teacher_feedback`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        studentId,
        feedback,
      }),
    });

    const result = await response.json();

    if (!result.success) {
      throw new Error();
    }

    return result.success;
  }
  catch {
    console.error("Error recording teacher feedback");
  }
};

// Accepts a drillInfo object from NewDrill, dispatches request to create new Drill, returns response to NewDrill
export const makeSyntheticDrill = async( drillInfo ) => {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);

    const response = await fetch(`${APIRootUrl}/teach/make_synthetic_drill`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        drillInfo,
      }),
    });
    
    const result = await response.json();

    if ( !result.success || !result.data ) {
      // Instead of throwing an error, just throw the status code to the catch block
      throw response.status;
    }

    return result.data;
  }
  catch (errorCode) {
    // Possible error codes
    // 405 means synthetic booking not allowed in this environment
    // 488 means no teacher found with given instructorEmail
    // 489 means lesson with this id already exists (generated from dateTimeNYC & level)
    // 487 means the lesson was created, but the instructor couldn't be assigned
    console.error("Failed to make synthetic drill");

    // Return object with errorCode to calling component
    // Caller can check for this and adjust accordingly
    return { errorCode };
  }
};

/**
 * Determine if teacher is on latest version of the Teach App
 * 
 * @param { String } localVersion - of Teach App
 * @returns data that includes whether teacher is on latest version of Teach App
 */
export const getVersionCheck = async () => {
  try {

    // Get user token from logged-in session
    let token;
    
    try {
      token = await getIdToken(firebaseAuth.currentUser);
    }
    catch {
      // if teacher is not logged in, allow to proceed for this specific API call
    }

    // Send request to check app version
    const response = await fetch(`${APIRootUrl}/teach/get_app_version`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        clientVersion: version.version,
      }),
    });

    const result = await response.json();

    if (!result.success || !result.data) {
      throw new Error();
    }

    return result.data;
  }
  catch {
    console.error("Error retrieving current Version Number");
    return false;
  }
};

// /**
//  * Sends a list of CANDIDATE-SPEAKER students to back end, receives a
//  * prioritized list of the top 6 students to promote
//  * 
//  * @param { Array } candidateStudentIds - All Candidates in the lesson to
//  * consider for promotion to Speaker
//  * @param { String } lessonId
//  * @param { number } presentSpeakerCount
//  * @returns { Promise<Array> } of top 6 Candidates for promotion, in priority order
//  */
// export const nominateCandidatesForSpeaker = async ( candidateStudentIds, lessonId, presentSpeakerCount ) => {
//   try {
//     const token = await firebase.auth().currentUser.getIdToken(false);
  
//     const response = await fetch(`${APIRootUrl}/teach/nominate_candidates_for_speaker`, {
//       method: "PUT",
//       headers: {
//         Accept: "application/json",
//         "Content-type": "application/json",
//       },
//       body: JSON.stringify({
//         token,
//         candidateStudentIds,
//         lessonId,
//         presentSpeakerCount,
//         version: version.version,
//       }),
//     });
    
//     const result = await response.json();
  
//     if ( !result.success || !result.data ) {
//       throw new Error();
//     }
  
//     return result.data;
//   }
//   catch {
//     console.error("Could not retrieve prioritized list of Candidates to promote");
//     return false;
//   }

// };

/**
 * Change a student's level
 * 
 * @param { String } studentId - the ID of the student to modify
 * @param { String } currentLevel - the level the student is at right now
 * @param { String } targetLevel - the level to change the student to
 * @returns { Object } - with 'success' boolean flag or 'error' string
 */
export const changeStudentLevel = async ( studentId, currentLevel, targetLevel ) => {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
  
    const response = await fetch(`${APIRootUrl}/teach/change_student_level`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        studentId,
        currentLevel,
        targetLevel,
      }),
    });
    
    return await response.json();
  }
  catch {
    return { success: false, error: "internal error" };
  }
};

/**
 * Change a student's VIP expiration date
 * 
 * @param { String } studentId - the ID of the student to modify
 * @param { String } currentDate - the student's current VIP expirtation date
 * @param { String } targetDate - the new VIP expiration date
 * @returns { Object } - with 'success' boolean flag or 'error' string
 */
export const changeStudentVipExpirationDate = async ( studentId, currentDate, targetDate ) => {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
  
    const response = await fetch(`${APIRootUrl}/teach/change_student_vip_expiration_date`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        studentId,
        currentDate,
        targetDate,
      }),
    });
    
    return await response.json();
  }
  catch {
    return { success: false, error: "internal error" };
  }
};

/**
 * Record the id of the student who was selected for a Turn
 * 
 * @param { String } studentId - of Speaker or Audience Member
 * @param { String } lessonId - of lesson in which student was selected
 * @param { String } lessonTreatmentType - of student when selected
 * @returns { Boolean } true if call succeeded, false if failed
 */
export const recordTurn = async ({ studentId = false, lessonId = false, lessonTreatmentType = "" }) => {
  try {
    // Make sure we have studentId, lessonId, & lessonTreatmentType
    if ( !studentId || !lessonId || !lessonTreatmentType ) {
      return;
    }
  
    // Use helper to get data from back end
    const result = await asyncFetchResult({
      endpoint: "record_turn",
      verb: "PUT",
      body: { studentId, lessonId, lessonTreatmentType },
      retryOnFailure: true,
    });

    // Return success/failure flag
    return result.success;
  }
  catch {
    console.error("Could not record turn");
    return false;
  }
};

/**
 * Save event (typically error) info in Firestore. Only used with talkka.X
 * logging functions (see services/logging/talkka.js)
 *
 * @param { Object } logInfo - from talkka.X, contains info to save in
 * Firestore about the event we're recording
 * @returns { Boolean } indicating if call succeeded
 */
export const saveLogEvent = async (logInfo) => {

  // Pass logInfo directly through as body
  // WARNING: Changing the endpoint called by this function could result in infinite loops if there's an error during a saveLogEvent call. So if we do change the endpoint, also change the logic in the catch block of asyncFetchData to reflect that new endpoint
  const result = await asyncFetchResult({
    endpoint: "public/save_log_event",
    body: logInfo,
  });

  // Return boolean indicating success or failure of call
  return result.success;
};

/**
 * Get info needed to search for word bundles
 *
 * @returns { Object } keys are search strings, values are objects with bundle
 * id and name
 */
export const getWordBundleSearchMap = async () => {
  try {
    const result = await asyncFetchResult({
      endpoint: "get_word_bundle_search_map",
    });

    if (
      !result
      || !result.success
      || !result.data
    ) {
      throw new Error("Bad fetch from back end - check CF logs");
    }

    return result.data.searchMap;
  }
  catch ( error ) {
    talkka.error(`Error getting word bundle search map: ${error}`);
    return false;
  }
};

/**
 * Get one wordBundle by its id
 *
 * @param { String } bundleId - of bundle to fetch
 * @returns { Object } bundle info from Firestore
 */
export const getOneWordBundle = async ( bundleId ) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "get_one_word_bundle",
      body: { bundleId },
    });

    if (
      !result
      || !result.success
      || !result.data
    ) {
      throw new Error("Bad fetch from back end - check CF logs");
    }

    return result.data.bundle;
  }
  catch ( error ) {
    talkka.error(`Error getting word bundle info: ${error}`);
    return false;
  }
};

/**
 * Apply the given update to the wordBundle with the given id in Firestore
 * 
 * @param { String } bundleId - of bundle to update
 * @param { Object } update - to apply to given bundle
 * @returns { Object } of udpated bundle info from Firestore
 */
export const updateOneWordBundle = async ({
  bundleId,
  update,
}) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "update_one_word_bundle",
      body: {
        bundleId,
        update,
      },
    });

    if (
      !result
      || !result.success
      || !result.data
    ) {
      throw new Error("Bad fetch from back end - check CF logs");
    }

    return result.data.updatedBundle;
  }
  catch ( error ) {
    talkka.error(`Error updating word bundle: ${error}`);
    return false;
  }
};

/**
 * Fetch all feedback given for classes taught by this teacher, between
 * startDate (inclusive) and endDate (exclusive)
 *
 * @param { object } startDate [optional] - Get feedback on or after this date.
 * In format yyyy-mm-dd. If omitted, is 7 days ago.
 * @param { string } endDate [optional] - Get feedback before this date. In
 * format yyyy-mm-dd. If omitted, is tomorrow.
 * @returns
 */
export const getFeedbackForLoggedInTeacher = async ({ startDate, endDate } = {}) => {
  try {

    const result = await asyncFetchResult({
      endpoint: "get_feedback_for_teacher",
      body: {
        startDate,
        endDate,
      },
    });

    if (
      !result
      || !result.success
      || !result.data
    ) {
      throw new Error("Bad fetch from back end - check CF logs");
    }

    return result.data;
  }
  catch ( error ) {
    talkka.error(`Error getting feedback for logged-in teacher: ${error}`);
    return false;
  }
};

/**
 * Gets feedback data for all teachers between given start date (inclusive) and
 * end date (exclusive)
 *
 * @param { String } startDate inclusive, in format YYYY-MM-DD
 * @param { String } endDate exclusive, in format YYYY-MM-DD
 */
export const getFeedbackForAllTeachers = async ({ startDate, endDate }) => {
  try {
    const result = await asyncFetchResult({
      endpoint: "get_feedback_for_all_teachers",
      body: {
        startDate,
        endDate,
      },
    });

    if (
      !result
      || !result.success
      || !result.data
    ) {
      throw new Error("Bad fetch from back end - check CF logs");
    }

    return result.data;
  }
  catch ( error ) {
    talkka.error(`Error getting feedback for all teachers: ${error}`);
    return false;
  }
};

/**
 * Retry room creation via Daily endpoint on failure, limited to 100 at a time
 * (this is happening because of changes to their rate limit)
 *
 * @returns { Boolean } - true on success, false on fail
 */
export const retryFailedRoomCreation = async () => {
  try {
    const result = await asyncFetchResult({
      endpoint: "retry_failed_room_creation",
    });

    // Return the entire response body which should include success and data
    return result;
  }
  catch ( error ) {
    talkka.error(`Error retrying room creation: ${error}`);
    return false;
  }
};

/**
 * Creates a new story from the data submitted through the AddStory form
 *
 * @param {object} storyData Contains all of the story data used to create the new story
 * @returns {object} Response containing success flag and story ID if successful
 */
export async function addStory(storyData) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      storyData,
    };

    const response = await fetch(`${APIRootUrl}/teach/add_story`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    
    if (!responseBody.success) {
      throw new Error("Failed to add story");
    }

    // Return the entire response body which should include success and data
    return responseBody;
  }
  catch (error) {
    console.error("Error adding story:", error); 
    return false;
  }
}

/**
 * Get story details given a story ID
 *
 * @param {string} storyId - ID of the story to fetch
 * @returns {Object} Story details from Firestore
 */
export async function getOneStory(storyId) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      storyId,
    };

    const response = await fetch(`${APIRootUrl}/teach/get_one_story`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    return responseBody.data;
  }
  catch {
    console.error("Error getting story"); 
    return false;
  }
}

/**
 * Edits an existing story from the data that an admin submitted
 *
 * @param {object} storyData Contains all of the story data used to edit the existing story
 * @returns {boolean} Success flag indicating if the operation succeeded
 */
export async function editStory(storyData) {
  try {
    const token = await getIdToken(firebaseAuth.currentUser);
    const body = {
      token,
      storyData,
    };

    const response = await fetch(`${APIRootUrl}/teach/edit_story`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const responseBody = await response.json();
    return responseBody.success;
  }
  catch (error) {
    console.error("Error editing story:", error); 
    return false;
  }
}
