import React, { useState } from "react";
import { addStory } from "../../../services/datastore";
// import { Timestamp } from "firebase/firestore";

/**
 * AddStory Component - Form for adding new stories to the database
 * @returns {JSX.Element} Story creation form
 */
export default function AddStory() {
  const [createdStoryId, setCreatedStoryId] = useState(null);

  const [storyData, setStoryData] = useState({
    storyWorld: "Atlangatepec",
    storyChapter: "",
    storyNumber: "",
    storyName: "",
    description: "",
    storyCreateDate: "",
    hideFromStoryTree: true,
    quizUrl: "",
    pictureUrl: "",
    relatedDrillTopic1: {
      name: "",
      urlL1: "",
      urlL2: "",
      urlL3: "",
    },
    storyText: "",
  });

  // Handles whether or not the error message is shown
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Handles whether or not the success message is shown
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Controls whether or not the submit button is clickable
  const [disableSubmit, setDisableSubmit] = useState(false);

  // Handles submitting the form to add the story
  const handleSubmit = async (event) => {
    setDisableSubmit(true);
    event.preventDefault();
    const result = await addStory(storyData);
    
    if (result && result.success) {
      setShowSuccessMessage(true);
      // You can now use result.data.id to get the new story ID
      setCreatedStoryId(result.data.id);
    }
    else {
      setShowErrorMessage(true);
    }
  };

  const resetStatus = () => {
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    setDisableSubmit(false);
  };

  // Handles changes for nested relatedDrillTopic1 object
  const handleDrillTopicChange = (event) => {
    const key = event.target.name;
    const value = trimSpaces(event.target.value);
    setStoryData({
      ...storyData,
      relatedDrillTopic1: {
        ...storyData.relatedDrillTopic1,
        [key]: value,
      },
    });
    resetStatus();
  };

  const handleChange = (event) => {
    const key = event.target.name;
    const value = trimSpaces(event.target.value);
    setStoryData({ ...storyData, [key]: value });
    resetStatus();
  };

  // Removes leading and trailing spaces from a string
  const trimSpaces = (string) => {
    if (typeof string !== "string" || !string) {
      return string;
    }
    return string.trim();
  };

  return (
    <div className="moduleDetails">
      {showSuccessMessage ? (
        <p className="add-module-success-message">
          The story has been successfully added.
          {createdStoryId && (
            <>
              <br />
              <a 
                href={"http://speak.beepboop.us/stories/catalog/" + createdStoryId + "?newest=true"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#006787", textDecoration: "underlin" }}
              >
                http://speak.beepboop.us/stories/catalog/${createdStoryId}?newest=true
              </a>
            </>
          )}
        </p>
      ) : null}
      {showErrorMessage ? (
        <p className="add-module-error-message">
          The story you are attempting to add already exists.
        </p>
      ) : null}
      <form className="moduleInformation" onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="storyWorld">
            Story World:
            <input
              name="storyWorld"
              type="text"
              className="input"
              value={storyData.storyWorld}
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyChapter">
            Story Chapter:
            <input
              name="storyChapter"
              type="text"
              className="input"
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyNumber">
            Story Number:
            <input
              name="storyNumber"
              type="text"
              className="input"
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyName">
            Story Name:
            <input
              name="storyName"
              type="text"
              className="input"
              required
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="description">
            Description:
            <textarea
              name="description"
              className="input"
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="hideFromStoryTree">
            Hide from Story Tree:
            <select
              name="hideFromStoryTree"
              value={storyData.hideFromStoryTree}
              onChange={(event) => {
                setStoryData({
                  ...storyData,
                  hideFromStoryTree: event.target.value === "true",
                });
                resetStatus();
              }}
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="quizUrl">
            Quiz URL:
            <input
              name="quizUrl"
              type="text"
              className="input"
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="pictureUrl">
            Picture URL:
            <input
              name="pictureUrl"
              type="text"
              className="input"
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="topicName">
            Related Drill Topic Name:
            <input
              name="name"
              type="text"
              className="input"
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="urlL1">
            L1 URL:
            <input
              name="urlL1"
              type="text"
              className="input"
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="urlL2">
            L2 URL:
            <input
              name="urlL2"
              type="text"
              className="input"
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="urlL3">
            L3 URL:
            <input
              name="urlL3"
              type="text"
              className="input"
              onChange={(event) => handleDrillTopicChange(event)}
            />
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="storyText">
            Story Text:
            <textarea
              name="storyText"
              className="input"
              style={{ height: "200px" }}
              onChange={(event) => handleChange(event)}
            />
          </label>
        </div>

        <br />
        <div>
          <input
            className={
              disableSubmit
                ? "add-module-submit-button-disabled"
                : "add-module-submit-button"
            }
            type="submit"
            value="Add Story"
            disabled={disableSubmit}
          />
        </div>
      </form>
      {showSuccessMessage ? (
        <p className="add-module-success-message">
          The story has been successfully added.
        </p>
      ) : null}
      {showErrorMessage ? (
        <p className="add-module-error-message">
          The story you are attempting to add already exists.
        </p>
      ) : null}
    </div>
  );
}
