import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Find icons to import at https://fontawesome.com/v5.15/icons
import {
  faCheck,
  faTimes,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import { recordFeedback } from "../../../../../../services/datastore";

import { useHolodeck } from "../../../../Holodeck";
import { useEventListener } from "../../../../../../hooks/useEventListener";

import BenchModal from "./BenchModal/BenchModal";

import "./currentSpeakerPanel.scss";

/**
 * 
 * @param { Object } lesson - Lesson info from Firestore, used to send feedback
 * @param { Object } module - Firestore object for module associated with lesson
 * @returns 
 */
const CurrentSpeakerPanel = ({
  lesson,
  module,
}) => {

  /*
   * CONTEXT
   */

  // Get Daily info from call context
  const {
    allowShortcuts,
    conversationMode,
    currentIsAudience,
    currentSpeakerParticipant,
    getParticipantName,
    nominateStudent,
    sendMessage,
    slideIndex,
    setTotalBeepboops,
  } = useHolodeck();

  /*
   * STATE
   */

  // Default feedback object for one call/turn
  const blankFeedback = {
    bien: 0,
    beepboop: 0,
  };

  // Remember number of beepboops and biens given during the current turn
  const [feedbackInThisTurn, setFeedbackInThisTurn] = useState( blankFeedback );

  // Boolean to trigger display of button that begins benching process of Audience Member
  const [ showBenchButton, setShowBenchButton ] = useState( true );

  // Boolean to trigger display of modal to confirm benching of Audience Member
  const [ showBenchModal, setShowBenchModal ] = useState( false );

  /*
   * USE EFFECT
   */

  // When current student changes, reset Current Speaker Panel: clear feedback map (because new student = new turn) & show Bench button again
  useEffect(() => {
    setFeedbackInThisTurn( blankFeedback );
    setShowBenchButton( true );

  }, [ currentSpeakerParticipant ]);

  /*
   * HELPERS
   */

  /**
   * Beepboop or Bien the current student. Default is bien, pass true to
   * beepboop
   * @param { Boolean } isBeepboop - Flag for whether should beepboop student
   */
  const giveFeedback = ( isBeepboop = false ) => {
    // If no student selected, give no feedback!
    if (!currentSpeakerParticipant) {
      return;
    }

    // Fire and forget async function to record feedback in Firestore
    recordFeedback(
      lesson.id,
      module.id,
      slideIndex,
      currentSpeakerParticipant.user_id, // studentId
      isBeepboop,
    );

    // Send message with feedback key ONLY to current student
    sendMessage( { feedback: isBeepboop ? "beepboop" : "bien" }, currentSpeakerParticipant.session_id);

    // Record feedback for this turn
    setFeedbackInThisTurn((prev) => ({
      bien: isBeepboop ? prev.bien : prev.bien + 1,
      beepboop: isBeepboop ? prev.beepboop + 1 : prev.beepboop,
    }));

    // Increment total beepboops if this was a beepboop
    if (isBeepboop) {
      setTotalBeepboops(prev => prev + 1);
    }
  };

  /*
   * EVENT LISTENERS
   */

  // Add keypress listeners for bien, beepboop, and clear student
  useEventListener({
    listenerShouldFire: allowShortcuts,
    listener: (event) => {
      // BIEN on "s"
      if ( event.key.toLowerCase() === "s" ) {
        // No argument means feedback is bien
        giveFeedback();
      }
      // BEEPBOOP on "d"
      else if ( event.key.toLowerCase() === "d" ) {
        // Pass true to give beepboop as feedback
        giveFeedback(true);
      }
      // Clear current student on "f"
      else if ( event.key.toLowerCase() === "f" ) {
        nominateStudent(null);
      }
    },
  });
  
  /*
   * RENDER
   */

  return (
    <div className={`studentPresence__currentSpeaker ${conversationMode ? "hidden" : "visible"}`}>
      <div
        className={`currentSpeaker__button ${currentSpeakerParticipant ? "bien" : "hidden"}`}
        onClick={ () => giveFeedback() }
        role="button"
      >
        <FontAwesomeIcon
          icon={faCheck}
        />
        <div className="currentSpeakerButton__feedbackCount">
          { feedbackInThisTurn.bien }
        </div>
        <div className="currentSpeakerButton__shortcutHint">
          s
        </div>
      </div>
      <div
        className={`currentSpeaker__button ${currentSpeakerParticipant ? "beepboop" : "hidden"}`}
        onClick={ () => giveFeedback(true) }
        role="button"
      >
        <FontAwesomeIcon
          icon={faTimes}
        />
        <div className="currentSpeakerButton__feedbackCount">
          { feedbackInThisTurn.beepboop }
        </div>
        <div className="currentSpeakerButton__shortcutHint">
          d
        </div>
      </div>
      <div className="currentSpeaker__name">
        {currentSpeakerParticipant
          && getParticipantName( currentSpeakerParticipant )
        }
        {// If current speaker is an Audience member, display "don't nominate this person again" button
          currentIsAudience
          && showBenchButton
          && (
            <button
              className="currentSpeaker__benchButton"
              onClick={ () => {
                setShowBenchButton( false );
                setShowBenchModal( true );
              } }
            >
              bench
            </button>
          )
        }
      </div>
      <div
        className={`currentSpeaker__button ${currentSpeakerParticipant ? "clear" : "hidden"}`}
        onClick={ () => nominateStudent(null) }
        role="button"
      >
        <FontAwesomeIcon
          icon={faUndo}
        />
        <div className="currentSpeakerButton__shortcutHint">
          f
        </div>
      </div>
      {// If Audience Turn "bench" button is clicked, display modal to confirm benching of Audience Member
        showBenchModal
        && (
          <BenchModal
            closeFunc={ () => setShowBenchModal( false ) }
          />
        )
      }
    </div>
  );
};

export default CurrentSpeakerPanel;
